import React, {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';
import {object} from "yup";
import BlankLayout from "../layouts/BlankLayout";
import CustomerPortal from '../pages/customer-portal/CustomerPortal';
import CompanyProfile from '../pages/apps/CRM/Settings/CompanyProfile';
import PaymentSettings from '../pages/apps/CRM/Settings/PaymentSettings';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/account/ResetPassword'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard/index'));

const Orders = React.lazy(() => import('../pages/apps/CRM/Orders/Orders'));
const OrderDetails = React.lazy(() => import('../pages/apps/CRM/Orders/OrderDetails'));
const NewOrder = React.lazy(() => import('../pages/apps/CRM/Orders/NewOrder'));
const ResourceCalendar = React.lazy(() => import('../pages/apps/CRM/Resources'));
const ResourceCalendarMonth = React.lazy(() => import('../pages/apps/CRM/Resources/CalendarMonth'));
const OrderConfirmation = React.lazy(() => import('../pages/apps/CRM/Orders/OrderConfirmation'));
const Employees = React.lazy(() => import('../pages/apps/CRM/Employees/Employees'));
const EcommerceProducts = React.lazy(() => import('../pages/apps/CRM/Products/Products'));

const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const Landing = React.lazy(() => import('../pages/landing'));


// customers
const OrderQuote = React.lazy(() => import('../pages/OrderQuote'));
const OrderConfirm = React.lazy(() => import('../pages/OrderConfirm'));
const OrderStatus = React.lazy(() => import('../pages/OrderStatus'));
const Terms = React.lazy(() => import('../pages/Terms'));
const Contact = React.lazy(() => import('../pages/Contact'));
const OrderForm = React.lazy(() => import('../pages/OrderForm'));

// drivers
const Jobs = React.lazy(() => import('../pages/Jobs'));
const JobDetails = React.lazy(() => import('../pages/JobDetails'));
const JobStatus = React.lazy(() => import('../pages/JobStatus'));


const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
    props: object
};

const LoadComponent = ({component: Component, props}: LoadComponentProps) => {
    return (
        <Suspense fallback={loading()}>
            <Component {...props}/>
        </Suspense>
    )
};

const AllRoutes = () => {
    const {layout} = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        {path: '/', element: <Root/>},
        {
            // public routes
            path: '/',
            element: <DefaultLayout/>,
            children: [
                {
                    path: 'account',
                    children: [
                        {path: 'login', element: <LoadComponent component={Login}/>},
                        {path: 'register', element: <LoadComponent component={Register}/>},
                        {path: 'confirm', element: <LoadComponent component={Confirm}/>},
                        {path: 'forget-password', element: <LoadComponent component={ForgetPassword}/>},
                        {path: 'reset-password', element: <LoadComponent component={ResetPassword}/>},
                        {path: 'logout', element: <LoadComponent component={Logout}/>},
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound}/>,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError}/>,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance}/>,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing}/>,
                },
            ],
        },
        {
            path: '/order-form',
            children: [
                {
                    path: '',
                    element: <LoadComponent component={OrderForm}/>
                },
            ]
        },
        {
            path: '/customer-portal',
            children: [
                {
                    path: ':orderId',
                    element: <LoadComponent component={CustomerPortal} props={{type: 'cleaning'}}/>
                },
                {
                    path: 'terms',
                    element: <LoadComponent component={Terms}/>
                },
                {
                    path: 'contact',
                    element: <LoadComponent component={Contact}/>
                },
                // {
                //     path: 'orders',
                //     children: [
                //         {
                //             path: ':orderId/quote',
                //             element: <LoadComponent component={OrderQuote} props={{type: 'moving'}}/>
                //         },
                //         {
                //             path: ':orderId/confirm',
                //             element: <LoadComponent component={OrderConfirm} props={{type: 'moving'}}/>
                //         },
                //         {
                //             path: ':orderId/status',
                //             element: <LoadComponent component={OrderStatus} props={{type: 'moving'}}/>
                //         }
                //     ]
                // },
                // {
                //     path: 'cleaning-orders',
                //     children: [
                //         {
                //             path: ':orderId/quote',
                //             element: <LoadComponent component={OrderQuote} props={{type: 'cleaning'}}/>
                //         },
                //         {
                //             path: ':orderId/confirm',
                //             element: <LoadComponent component={OrderConfirm} props={{type: 'cleaning'}}/>
                //         },
                //         {
                //             path: ':orderId/status',
                //             element: <LoadComponent component={OrderStatus} props={{type: 'cleaning'}}/>
                //         }
                //     ]
                // },
            ]
        },
        {
            path: '/crew',
            element: <PrivateRoute roles={'driver,admin'} component={BlankLayout}/>,
            children: [
                {
                    path: '',
                    element: <LoadComponent component={Jobs}/>
                },
                {
                    path: 'jobs/:date',
                    element: <LoadComponent component={Jobs}/>
                },
                {
                    path: 'jobs/:jobId/details',
                    element: <LoadComponent component={JobDetails}/>
                },
                {
                    path: 'jobs/:jobId/status',
                    element: <LoadComponent component={JobStatus}/>
                }

            ]
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'admin'} component={Layout}/>,
            children: [
                {
                    path: 'dashboard',
                    element: <LoadComponent component={Dashboard}/>,
                },
                {
                    path: 'orders',
                    element: <LoadComponent component={Orders}/>,
                },
                {
                    path: 'orders/:orderId',
                    element: <LoadComponent component={OrderDetails}/>,
                },
                {
                    path: 'orders/new',
                    element: <LoadComponent component={NewOrder}/>,
                },
                {
                    path: 'order-confirmation',
                    element: <LoadComponent component={OrderConfirmation}/>,
                },
                {
                    path: 'employees',
                    element: <LoadComponent component={Employees}/>,

                },

                {
                    path: 'products',
                    element: <LoadComponent component={EcommerceProducts}/>,
                },

                {
                    path: 'resource-calendar',
                    children: [
                        {
                            path: 'daily',
                            children: [
                                {
                                    path: '',
                                    element: <LoadComponent component={ResourceCalendar}/>
                                },
                                {
                                    path: ':date',
                                    element: <LoadComponent component={ResourceCalendar}/>
                                }
                            ]
                        },
                        {
                            path: 'monthly',
                            element: <LoadComponent component={ResourceCalendarMonth}/>
                        },

                    ]
                },
                {
                    path: '/settings',
                    children: [
                        {
                            path: 'company-profile',
                            element: <LoadComponent component={CompanyProfile}/>
                        },
                        {
                            path: 'payment-settings',
                            element: <LoadComponent component={PaymentSettings} props={{type: 'cleaning'}}/>
                        },
                    ]
                },
            ],
        },
    ]);
};

export {AllRoutes};
