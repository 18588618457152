import React, {useEffect, useState, useContext} from "react";
import {
    Accordion,
    AccordionContext,
    Button,
    Card,
    Col,
    Modal,
    ModalBody, ModalHeader,
    Row,
    useAccordionButton
} from "react-bootstrap";
import {t} from "i18next";
import {Link} from "react-router-dom";
import classNames from "classnames";
import parse from "html-react-parser";
import {HeaderAndDivider} from "react-bootstrap-typeahead/types/components/Menu/Menu.stories";

const CleaningInformation = () => {

    const [info, setInfo] = useState([]);
    const [showAll, setShowAll] = useState(false);

    const CustomToggle = ({children, eventKey, containerClass, linkClass, callback}) => {
        const {activeEventKey} = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <p className="bold my-0">
                <Link
                    to="#"
                    className={classNames(linkClass, {
                        collapsed: !isCurrentEventKey,
                    })}
                    onClick={decoratedOnClick}>
                    {children}
                </Link>
            </p>
        );
    };

    const CustomAccordion2 = ({item, index}) => {
        return (
            <Row className="my-2 p-1 py-2" style={{borderRadius: 10, backgroundColor: "#FAFBFE"}}>
                <Col>
                    {/*<Card.Header>*/}
                    <CustomToggle
                        eventKey={String(index)}
                        containerClass="m-0"
                        linkClass="custom-accordion-title d-block py-1 bold">
                        {t(item.title)}
                        <span className="align-items-end text-end float-end d-block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.044" height="11.066"
                                 viewBox="0 0 19.044 11.066">
                            <g id="Group_150" data-name="Group 150" transform="translate(17.63 1.414) rotate(90)">
                                <line id="Line_5" data-name="Line 5" x2="8.238" y2="8.108" fill="none" stroke="#727cf4"
                                      stroke-linecap="round" stroke-width="2"/>
                                <line id="Line_6" data-name="Line 6" x1="8.238" y2="8.108"
                                      transform="translate(0 8.108)" fill="none" stroke="#727cf4" stroke-linecap="round"
                                      stroke-width="2"/>
                            </g>
                            </svg>
                        </span>

                    </CustomToggle>
                    {/*</Card.Header>*/}
                    <Accordion.Collapse eventKey={String(index)}>
                        <div>
                            {/* <small>{parse(item.body.replace(/\n/g, '<br/>'))}</small> */}
                            <ul style={{fontSize : '13px'}}>
                            {item.body.map((info, i) => {
                                return <li>{t(info)}</li>
                            })}
                            </ul>
                        </div>
                    </Accordion.Collapse>
                </Col>
            </Row>
        );
    };

    useEffect(() => {
        // get cleaning information text
        setInfo([
            {
                title: 'What is included in the moving cleaning?',
                body: ['Vacuuming of carpets, floors and possibly other textiles',
                    'Dusting and washing of all accessible surfaces, including floors, walls and ceilings',
                    'Washing of cupboards and drawers, both inside and outside',
                    'Washing white and brown goods',
                    'Interior and exterior washing of windows',
                    'Thorough cleaning of the kitchen and bathroom, including fans and drains']
            }, {
                title: 'What’s not included in the moving cleaning?', 
                body: ['Cleaning after renovation/construction dust',
                    'Cleaning of the basement or attic',
                    'Removal of extreme stains',
                    'Removing screws/nails from walls/ceilings',
                    'Moving heavy furniture/white goods/items/rubbish',
                    'Washing of the garden/outdoor area/balcony',
                    'Facade washing',
                    'We do not wash over 300 cm in height']
            }, {
                title: 'We take my own cleaning equipment',
                body: []
            }, {
                title: '100% satisfaction guarantee',
                body: []
            }, {
                title: 'Who is coming?',
                body: []
            }
        ])

    }, [])

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title><strong>{t('Important information')}</strong></Card.Title>
                    <Accordion defaultActiveKey="0" id="accordion" className="custom-accordion" alwaysOpen={true}>
                        {(showAll ? info : [...info].splice(0,2)).map((item, index) => {
                            return <CustomAccordion2 key={index} item={item} index={index}/>;
                        })}
                    </Accordion>
                    <Col className="mt-3 text-center">
                        <Button
                            className="font-10 align-middle my-auto px-2 py-1"
                            size={"lg"}
                            onClick={() => {
                                setShowAll(!showAll);
                            }}
                        >{t(showAll ? 'See less information' : 'See all information')}</Button>
                    </Col>
                </Card.Body>
            </Card>
        </>
    )
}

export default CleaningInformation;