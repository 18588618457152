import {Card} from "react-bootstrap";
import parse from "html-react-parser";
import {t} from "i18next";
import CustomerRating from "../../components/Rating";
import React, {useEffect} from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";

const Feedback = ({type}) => {
    const title = type === 'moving' ? 'How was your move?' : 'How was the cleaning?';
    const {orderId} = useParams();

    const fetchRating = () => {
        axios.get(`customer-portal/orders/${orderId}/job-ratings`).then(function(response){
            console.log(response);
        });
    }

    useEffect(() => {

    }, [])
    return (
        <Card>
            <Card.Body className="text-center">
                <div className="py-1">
                    <h2 className="font-20" style={{ fontWeight: "bold"}}>{t(title)}</h2>
                    <p>{parse(t(`Your feedback will help us improve cleaning experience better.`))}</p>
                    <CustomerRating value={0}/>
                </div>
            </Card.Body>
        </Card>
    );
}

export default Feedback;