import './../assets/css/custom.css';
import moment from 'moment/moment';

const JobEvents = ( {jobEvents, style, withTime = true} ) => {

    return <>
        {jobEvents.map((obj, i) =>
            <div className={`ps-4 pe-2 bold font-15`} style={style} key={i}>
                <span className={`feed-dot ${obj.current && 'current'} ${!obj.current && obj.time && 'fill'}`}></span>
                <div className={`feed-line ${i !== jobEvents.length - 1 && 'feed-line-border'}`} style={style}></div>
                
                { obj?.text } 

                { obj?.time && withTime && <span className="float-end">{ moment(obj?.time).format('hh:mm') }</span> }

            </div>
        )}
    </>
}

export default JobEvents;