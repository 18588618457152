// @flow
import React from 'react';
import Routes from './routes/Routes';
import {QueryClientProvider, QueryClient} from "react-query";

// setup fake backend

// Themes

// For Saas import Saas.scss
import './assets/scss/Saas.scss';
import '../src/assets/scss/app.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';

// configure fake backend
// configureFakeBackend();

type AppProps = {};

/**
 * Main app component
 */
const App = (props: AppProps): React$Element<any> => {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <Routes></Routes>
        </QueryClientProvider>
    );
};

export default App;
