import React, {useState} from 'react';
import OrderSummary from "../quote/OrderSummary";
import {useNavigate, useParams} from "react-router-dom";
import {t} from "i18next";
import {Button, Form, Row} from "react-bootstrap";
import ParkingOptions from "../quote/ParkingOptions";
import EntryOptions from "../quote/EntryOptions";
import CleaningChecklist from "../quote/CleaningChecklist";
import Cookies from 'js-cookie';
import axios from 'axios';
import AddressInformation from '../quote/AddressInformation';

const CustomerPortalAccepted = (props) => {
    const [validated, setValidated] = useState(false);
    const [parking, setParking] = useState('');
    const [entry, setEntry] = useState('');
    const [keyDescription, setKeyDescription] = useState(null);
    const [currentEditor, setCurrentEditor] = useState(null);

    const titles = {
        cleaning: t('Your Cleaning Order'),
        moving: t('Your Move Order')
    }

    const {orderId} = useParams();

    const handleParkingOptionSelect = (data) => {
        console.log(data);
        setParking(data);
    };

    const handleEntryOptionSelect = (data) => {
        console.log(data);
        setEntry(data);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);

        if(form.checkValidity()){
            const dataObj = { 
                    "details": {
                    "parking_type": parking, // free or paid
                    "getting_in": entry, // key or meet
                    }
                }

            if(parking === 'meet'){
                delete dataObj.getting_in;
            }
        
            axios.post('customer-portal/orders/'+orderId+'/confirm', dataObj).then(function(response){

                if(response.data.status.code === '200'){
                    props.setCurrentStep('status');
                    Cookies.set('portal-step', 'status');
                }
            });
        }
    
      };

    const selectedProducts = Cookies.get('selectedServices') ? JSON.parse(Cookies.get('selectedServices')): [];

    return (
        <>
            <AddressInformation
                type={props.type}
                newData={props.newOrder}
                onEdit={() => setCurrentEditor('information')}
                onConfirm={() => setCurrentEditor(null)}
                setOrder={ props.setOrder }
                isEditable={false}
            />
            <OrderSummary
                hideButton={true}
                total={Cookies.get('total')}
                selectedProducts={selectedProducts}
            />
        </>
    )
}

export default CustomerPortalAccepted;