import React, {useState} from "react";
import {Card, Form} from "react-bootstrap";
import {t} from "i18next";

const ParkingOptions = ({ value, onSelect, isButtonActive}) => {

    const [selected, setSelected] = useState(value);

    const handleSelect = (e) => {
        setSelected(e.currentTarget.value)
        onSelect(e.currentTarget.value);
    }
 
    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title><strong>{t('Parking nearby?')}</strong></Card.Title>
                    
                    <Form.Check 
                    name="parking-check" 
                    disabled={isButtonActive ? false : true} 
                    required 
                    onChange={handleSelect} 
                    checked={selected === 'free'} 
                    type="radio" 
                    label={t('Free parking nearby')} 
                    value="free" 
                    className="my-2" 
                    style={{fontWeight: 100}}
                    />

                    <Form.Check 
                    name="parking-check" 
                    disabled={isButtonActive ? false : true} 
                    required 
                    onChange={handleSelect} 
                    checked={selected === 'paid'} 
                    type="radio" 
                    label={t('Only paid parking')} 
                    value="paid" 
                    className="my-2" 
                    style={{fontWeight: 100}}/>

                </Card.Body>
            </Card>
        </>
    );
}

export default ParkingOptions;