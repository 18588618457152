import React from 'react';
import {Container} from "react-bootstrap";
import {Helmet} from 'react-helmet';
import Footer from "./Footer";


const Layout = ({children, gradientHeight = 268}) => {

    const gradientEnd = 74 + gradientHeight;

    const style = {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: 428,
        height: '100% !important',
        position: 'relative',
        overflow: 'hidden',
        margin: '0 auto',
        background: `linear-gradient(to bottom, white 0 74px, rgb(114,124,245) 74px ${gradientEnd}px, white ${gradientEnd}px 100%)`
    };

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=yes"/>
                <style>
                    {
                        'html { height: 100%; background-color: #252528; -moz-transform: scale(1, 1); /* Moz-browsers */\n' +
                        '    zoom: 1; /* Other non-webkit browsers */\n' +
                        '    zoom: 100%; /* Webkit browsers */\n } body { background-color: #252528; width: 100%; height: 100%; margin:0 }'
                    }
                </style>
            </Helmet>
            <div id={"app_container"} className="float-center border-1" style={style}>
                <Container className="pt-0 pb-1" style={{padding: "0 20px"}}>
                    {children}
                </Container>
                <Footer/>
            </div>
        </>
    )
}

export default Layout;