import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";


import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import App from './App';

import {Provider} from 'react-redux';
import {configureStore} from './redux/store';
import {DndProvider} from "react-dnd";
import {HTML5Backend} from 'react-dnd-html5-backend';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}


ReactDOM.render(
    <DndProvider backend={HTML5Backend}>
        <Provider store={configureStore({})}>
            <App/>
        </Provider>
    </DndProvider>,
    document.getElementById('root')
);
