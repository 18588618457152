const getTimeValue = (formatted) => {
    const match = formatted.match(/([01]?[0-9]|2[0-3]):([0-5][0-9])(:00)?/);

    if (!match) {
        throw new Error('Invalid date format');
    }

    return Number(match[1]) + Number(match[2])/60;
}

const getFormattedTime = (value) => {

    let hours = Math.floor(value);

    if (hours < 10) {
        hours = "0" + hours;
    }

    let minutes = (value % 1) * 60;

    if (minutes === 0) {
        minutes = "0" + minutes;
    }

    return `${hours}:${minutes}`;
}

export { getFormattedTime, getTimeValue };