import './../assets/css/custom.css';
import moment from 'moment/moment';

const JobReport = ( {report, key} ) => {

    return <>
            <table className="my-2" key={key}>
                <tbody>
            <tr xs={2}>
                {report.image_url &&
                    <td style={{width : '100px'}}>
                        <img src={`https://cleaning.between.as/storage/${report.image_url}`} width={'100px'} alt='img' className="fluid"></img></td> 
                }
                <td className="ps-1" valign="top">
                    <h5 className="bold mb-0">Scratches</h5>
                    <p className="mb-0">{report.comments}</p>

                </td>
            </tr>
            </tbody>
        </table>
        <hr />
    </>
}

export default JobReport;