export const addressFormat = (address, isBreak = false) => {
    const br = isBreak ? "<br>" : ", ";
    const extra = address.section_id !== "Z000" && address.section_id !== "" ? ` (${address.section_id})` : "";
    return address.street+br+address.zipcode+" "+address.place+extra;
};

export const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };