// @flow
import React from 'react';
import Rating from 'react-rating';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import { faStar as startSolid } from "@fortawesome/pro-solid-svg-icons";
import axios from 'axios';
import { useParams } from "react-router-dom";

type RatingProps = {
    tag: string,
    className?: string,
    value?: number,
};

/**
 * Rating
 */
const CustomerRating = (props: RatingProps): React$Element<any> => {
    const rating = Math.floor(props.value || 0);
    const {orderId} = useParams();

    const handleRatingClick = ( value ) => {

        console.log( value );

        axios.post(`customer-portal/orders/${orderId}/job-ratings`, {rating : value}).then(function(response){
            console.log(response);
        });
    }

    return (
        <>
            <Rating
                onClick={ handleRatingClick }
                initialRating={rating}
                emptySymbol={<FontAwesomeIcon icon={faStar} style={{fontSize: '35px'}} className={'f-20'} />}
                fullSymbol={<FontAwesomeIcon icon={startSolid}  className={'f-20'} style={{fontSize: '35px', color: '#ffbc00'}} />}
            />
        </>
    );
};

export default CustomerRating;
