import {Badge, Button, Card, Col, Image, Row} from "react-bootstrap";
import box from "../../assets/images/icons/box-taped.png";
import bed from "../../assets/images/icons/bed-front.png";
import house from "../../assets/images/icons/house.png";
import React from "react";

const InventoryViewer = (props) => {
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <p className="mb-0">{props.title || "Inventory"}</p>
                        <strong className="data-strong">32m3 / 350 kg</strong>
                    </Col>
                    {
                        props.onEdit ?
                            <Col className={"align-content-end text-end"}>
                                <Button
                                    className="font-10 align-middle my-auto"
                                    size={"sm"}
                                    onClick={props.onEdit}
                                >Manage Inventory</Button>
                            </Col>
                            : ''
                    }
                </Row>
                <Row className={"mt-2"}>
                    <div className={"d-inline d-flex"} style={{width: "auto"}}>
                        <Badge className="p-1 mb-1 align-middle" bg={"light"} text={"dark"}>
                            <Image src={box} className={"pe-1"}/>4 Boxes
                        </Badge>
                    </div>
                    <div className={"d-inline d-flex"} style={{width: "auto"}}>
                        <Badge className="p-1 mb-1 align-middle" bg={"light"} text={"dark"}>
                            <Image src={bed} className={"pe-1"}/>4 Big items
                        </Badge>
                    </div>
                    <div className={"d-inline d-flex"} style={{width: "auto"}}>
                        <Badge className="p-1 mb-1 align-middle" bg={"light"} text={"dark"}>
                            <Image src={bed} className={"pe-1"}/>4 Small items
                        </Badge>
                    </div>
                    <div className={"d-inline d-flex"} style={{width: "auto"}}>
                        <Badge className="p-1 mb-1 align-middle" bg={"light"} text={"dark"}>
                            <Image src={house} className={"pe-1"}/>4 Special items
                        </Badge>
                    </div>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default InventoryViewer;