import {Button, Card, Col, Row} from "react-bootstrap";
import React, {useState} from "react";
import InventoryViewer from "./InventoryViewer";

const InventoryEditor = (props) => {
    return (
        <Card>
            <Card.Body>
                <Row className="mb-2">
                    <Col><strong className="data-strong">Inventory<br/>32m3 / 350 kg</strong></Col>
                    <Col className={"text-end font-13"}><p>8 items total</p></Col>
                </Row>
                <Row style={{backgroundColor: "#FAFBFE", borderRadius: "14px"}} className="m-0 py-3">
                    <Col>
                        <table style={{width: "100%", height: "100%"}} className="font-13">
                            <tbody>
                            <tr className="m-0">
                                <td><strong className="font-14">Moving boxes/bag</strong></td>
                            </tr>
                            <tr>
                                <td style={{minHeight: "140px", padding: "10px 0 0 0"}} valign={"top"}
                                    align={"left"}>
                                    - Needs to be carried by 1 person
                                    <br/>- Max 35kg pr item
                                    <br/>
                                    <br/>
                                    Typical items: Chair, even table, lamp, skis, bicycle, tyres
                                </td>
                            </tr>
                            <tr>
                                <td className="pt-2">1x1m | 15kg</td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col sm={3} xs={3} md={3} lg={3} className="text-center">
                        <table style={{width: "100%"}}>
                            <tbody>
                            <tr className="m-0">
                                <td><Button className={"py-0"}
                                            style={{fontSize: '24px', width: '40px', borderRadius: '6px'}}
                                            size={"sm"}>+</Button></td>
                            </tr>
                            <tr>
                                <td style={{padding: "30px 0"}}>2</td>
                            </tr>
                            <tr>
                                <td><Button className={"py-0"}
                                            style={{fontSize: '24px', width: '40px', borderRadius: '6px'}}
                                            size={"sm"}>–</Button></td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <Row className={"text-center mt-3 align-middle"}>
                    <div style={{width: "100%"}}>
                        <Button
                            size="md" className="float-center mx-1" style={{width: "180px"}}
                            onClick={props.onConfirm}
                        >
                            <strong className="data-strong">Confirm Inventory</strong>
                        </Button>
                    </div>
                </Row>
            </Card.Body>
        </Card>
    );
}

const Inventory = (props) => {
    const [editMode, setEditMode] = useState(false);

    const openEditor = () => {
        setEditMode(true);
        props.onEdit();
    }

    const confirmChanges = () => {
        props.onConfirm();
        setEditMode(false);
    }

    if (editMode) {
        return <InventoryEditor onConfirm={confirmChanges}/>
    }

    return <InventoryViewer onEdit={openEditor}/>
}

export default Inventory;