import React, {useState} from 'react';
import AddressInformation from "../quote/AddressInformation";
import Inventory from "../quote/inventory";
import CleaningServices from "../quote/CleaningServices";
import CleaningInformation from "../quote/CleaningInformation";
import OrderSummary from '../quote/OrderSummary';


const CustomerPortalQuote = (props) => {

    const [currentEditor, setCurrentEditor] = useState(null);
    const [currentPin, setCurrentPin] = useState(0);

    const handleServiceChange = () => {
        props.fetchSelectedProducts();
    }

    return (
        <>
            {props.type === 'moving' ? (currentEditor && currentEditor !== 'inventory' ? '' :
            <Inventory
                        onEdit={() => setCurrentEditor('inventory')}
                        onConfirm={() => setCurrentEditor(null)}
                    />
            ) : ''}
            {currentEditor && currentEditor !== 'information' && props.newOrder ? '' :
            <>
                <AddressInformation
                    type={props.type}
                    newData={props.newOrder}
                    onEdit={() => setCurrentEditor('information')}
                    onConfirm={() => setCurrentEditor(null)}
                    setOrder={ props.setOrder }
                />
            </>

            }

            {props.type === 'cleaning' ?
                <>
                    <CleaningServices 
                        services={props.allProducts} 
                        order={props.newOrder} 
                        onServiceChange={handleServiceChange} 
                        selectedProducts={props.selectedServices}
                    />
                    <CleaningInformation/>
                </>
                : ''
            }

            <OrderSummary
                hideButton={!!currentEditor}
                order={props.newOrder}
                total={props.total}
                selectedProducts={props.selectedServices}
                currentPin={currentPin}
                setCurrentPin={setCurrentPin}
                currentStep={props.currentStep}
                setCurrentStep={props.setCurrentStep}
                orderStatus={props.setOrderStatus}
                setOrderStatus={props.setOrderStatus}
            />
        </>)
        ;
};


export default CustomerPortalQuote;