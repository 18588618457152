import React, {useState} from "react";
import {Card, Form} from "react-bootstrap";
import {t} from "i18next";
import {FormInput} from "../../components";

const EntryOptions = ({  entry, setEntry, setKeyDescription, isButtonActive, entryOption, entryOptionDesc }) => {

    const [selected, setSelected] = useState(entryOption);
 
    const handleSelect = (e) => {
        setSelected(e.currentTarget.value)
        setEntry(e.currentTarget.value);
    }

    const handleKeyDescriptionChange = (e) => {
        setKeyDescription(e.currentTarget.value);
    }

    useState(() => {
        if(entryOption){
            setEntry(entryOption);    
        }
    })

    console.log('entryOption', entryOption)

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title><strong>{t('How do we get in?')}</strong></Card.Title>
                    <Form.Check 
                        name="entry-check" 
                        required 
                        disabled={isButtonActive ? false : true} 
                        onChange={handleSelect} 
                        checked={entry === 'meet'} 
                        type="radio" 
                        label={t('Meet us at the door and let us in')} 
                        value="meet" 
                        className="my-2" 
                        style={{fontWeight: 100}}
                    />
                    <Form.Check 
                        name="entry-check" 
                        required 
                        disabled={isButtonActive ? false : true} 
                        onChange={handleSelect} 
                        checked={entry === 'key'} 
                        type="radio" 
                        label={t('Key')} 
                        value="key" 
                        className="my-2" 
                        style={{fontWeight: 100}}
                    />
                    { entry === 'key'
                        ? <FormInput required placeholder={t('Describe where we can pick the key up')} className="mt-2" onChange={handleKeyDescriptionChange} defaultValue={entryOptionDesc}/>
                        : ''
                    }
                </Card.Body>
            </Card>
        </>
    );
}

export default EntryOptions;