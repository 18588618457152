import React from "react";
import {Card, Col, Row} from "react-bootstrap";
import {t} from "i18next";

const CheckIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="22.547" height="16.105" viewBox="0 0 22.547 16.105">
        <path id="check"
              d="M54,96.5a1.613,1.613,0,0,1,0,2.28L41.118,111.659a1.613,1.613,0,0,1-2.28,0L32.4,105.218a1.612,1.612,0,1,1,2.28-2.28l5.3,5.3L51.725,96.5a1.613,1.613,0,0,1,2.28,0Z"
              transform="translate(-31.925 -96.025)" fill="#536de6"/>
    </svg>;
}

const CleaningChecklist = () => {

    const list = [
        t('Remove all trash bags and other objects'), 
        t('Remove items from the fridge'),
        t('Other thing')
    ]

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title><strong>{t('Please prepare the following before we arrive')}</strong></Card.Title>
                    { list.map((entry, i) => {
                        return <Row key={i} className="mt-3 py-3 ps-1" style={{borderRadius: 10, backgroundColor: "#FAFBFE"}}>
                                <Col xs={1}><CheckIcon/></Col>
                                <Col className="ms-2"><strong>{entry}</strong></Col>
                            </Row>
                        
                    })}
                </Card.Body>
            </Card>
        </>
    )
}

export default CleaningChecklist;