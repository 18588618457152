import React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {t} from "i18next";


const Footer = () => {

    const style = {
        height: "89px",
    }

    return (
        <>
            <div style={style} className="bg-primary p-3 mt-2 pb-3">
                <Row>
                    <Col className="text-center"><Link to={'/customer-portal/contact'} className="text-white"><h4><strong>{t('Contact us')}</strong></h4></Link></Col>
                    <Col className="text-center"><Link to={'/customer-portal/terms'} className="text-white"><h4><strong>{t('Terms & Condition')}</strong></h4></Link></Col>
                </Row>
                <Row>
                    <Col className="text-center text-white"><p>{t('Software by Between AS')}</p></Col>
                </Row>
            </div>
        </>
    )
}

export default Footer;