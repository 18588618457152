import React, {useEffect, useState} from 'react';
import {Card} from "react-bootstrap";
import MovingRoute from "../quote/MovingRoute";
import {useParams} from "react-router-dom";
import OrderSummary from "../quote/OrderSummary";
import VippsButton from "../../components/VippsButton";
import {t} from "i18next";
import Feedback from "../quote/Feedback";
import {products} from "../quote/fakedata";
import Cookies from 'js-cookie';
import '../../assets/css/custom.css';
import JobEvents from "../../components/JobEvents";
import axios from 'axios';
import JobReport from '../../components/JobReport';
import moment from "moment";
import _ from 'lodash';

const Notes = ({reports}) => {

    return (
        <Card>
            <Card.Body>
                <Card.Title><strong>Reports and Notes</strong></Card.Title>
                {(reports || []).map((report, i) => {
                    return <JobReport report={report} key={i} />
                })}
            </Card.Body>
        </Card>
    );
}

const titles = {
    cleaning: {
        ongoing: t('Your Cleaning Status'),
        completed: t('Your Cleaning is Completed')
    },
    moving: {
        ongoing: t('Your Move Status'),
        completed: t('Your Move is Completed')
    }
}


const PaymentEvents = ({ events }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title><strong>Payment Status</strong></Card.Title>
                { events.map((event, i) => {
                    const date = moment(event.created_at).format('DD.MM.YYYY');
                    return (
                        <div className="d-flex justify-content-between" key={i}>
                            <div>{_.capitalize(t(event.status))} - {date}</div>
                        </div>
                    )
                })}
            </Card.Body>
        </Card>
    );
}

const CustomerPortalStatus = ({type, order}) => {
    const [jobEvents, setJobEvents]  = useState([
        {id : 1, event : 'LEFT_OFFICE', text : t('Left office'), color : "btn-danger"},
        {id : 2, event : 'ARRIVED_AT_SITE', text : t('Arrived'), color : "btn-warning"},
        {id : 3, event : 'STARTED_JOB', text : t('Started cleaning'), color : "btn-primary"},
        {id : 4, event : 'FINISHED_JOB', text : t('Finished cleaning'), color : "btn-info"},
    ]);
    const {orderId} = useParams();
    const [isCompleted, setIsComplete] = useState(false);
    const [reports, setOrderReports] = useState([]);

    const selectedProducts = Cookies.get('selectedServices') ? JSON.parse(Cookies.get('selectedServices')): [];

    const fetchOrderEvents = () => {
        axios.get('customer-portal/orders/'+orderId+'/job-events').then(function(response){
            const events = response.data.data;
            if(events.length > 0){
                events.map((ev, i) => {
                    setJobEvents(jobEvents =>
                        jobEvents.map(obj => {
                            if(ev.type === obj.event){
                                return {...obj, time: ev.timestamp, current : ev.is_current };
                            }

                            return obj;
                        }),
                    );

                })

            }
            if(events.length === 4){
                setIsComplete(true);
                Cookies.set('portal-status', 'quote');
            }
        });
    }

    const fetchOrderReports = () => {
        axios.get('customer-portal/orders/'+orderId+'/job-reports').then(function(response){
            const reports = response.data.data;
            setOrderReports(reports);
        });
    }

    useEffect(() => {
        fetchOrderEvents();
        fetchOrderReports();
    }, [])

    const handleVippsClick = () => {
        axios.post(`customer-portal/orders/${orderId}/payment/init`, {
            return_url: `https://cleaning.between.as/customer-portal/${orderId}`,
        }).then(function(response) {
            const data = response.data.data;
            window.location.href = data.payment_url;
        });
    }

    return (
        <>
        {isCompleted ?
            (
                <>
                    <Feedback type={type}/>
                    <Notes reports={reports}/>
                    <OrderSummary
                        hideButton={true}
                        products={products}
                        total={Cookies.get('total')}
                        selectedProducts={selectedProducts}
                    />
                    { order.status === 'Paid' ?
                        <PaymentEvents events={order.payment.events}/>
                        : <div className="text-center mt-4 mb-5">
                            <VippsButton click={ handleVippsClick }/>
                        </div>
                    }
                </>
            ) :
            (
                <>
                    {/* <ProfileCard withButtons={true} customer={order.customer}/> */}
                    <Card className='p-4'>
                        <JobEvents jobEvents={jobEvents} jobId={orderId} style={{height : '40px'}} withTime={false} />
                    </Card>
                    {type === 'moving' ?
                        <MovingRoute/> : ''
                    }
                </>
            )
        }
        </>
    )
};

export default CustomerPortalStatus;