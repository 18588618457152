// @flow
import React, { useEffect, useState } from 'react';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';

// components
import PageTitle from '../../../../components/PageTitle';

import { t } from 'i18next';
import profileImg from '../../../../assets/images/users/avatar-2.jpg';
import PhoneWithCountry from '../../../../components/PhoneWithCountry';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import MessageAlert from '../../../../components/MessageAlert';

// main component
const PaymentSettings = (): React$Element<React$FragmentType> => {

  const [image, setImage] = useState(null);
  const [validated, setValidated] = useState(false);
  const companyId = 1;
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name : '',
    registration_number : '',
    phone : '',
    address : '',
    logo_url : '',
  });
  const [error, setError] = useState({
    message: '',
    errors: ''
   });

  const handleSubmit = (event) => {
        
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    console.log(form.checkValidity());
    if(form.checkValidity()){
        if(image){        
            if (!(image instanceof File)) {
                // get file name from url
                const full = image.preview.split('/').pop();
                return Promise.resolve({data: {data: {links: {full}}}});
            }
            const data = new FormData();
            data.append('file', image, image.fileName);

            uploadFile(image)
            .then((response) => {
                const data = response.data.data;
                if (!data) {
                    throw new Error('Something went wrong');
                }
                console.log('upload success', data.links.full);
                setFormData({...formData, logo_url  : data.links.full});

                submitForm(formData, data.links.full);
            
            })
            .catch(() => {
                setError({
                    message: t('Something went wrong with the image upload'),
                    errors: {image: t('Something went wrong with the image upload')}
                });
            });
        }else{
            submitForm(formData, null);
        }
    }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const submitForm = ( data, image) => {
        
        if(image){
            data['logo_url'] = image;
        }
        //send the report of the job
        axios.put('/companies/'+companyId, data).then(function(response){
            console.log('company update response', response.data);

            setValidated(false);
        });
    }

    const fetchCompanyDetails = () => {
        axios.get('/companies/'+companyId).then(function(response){
            console.log('company response', response.data);
            const obj = response.data.data;
            let updatedValue = {"name" : obj.name,
                                "registration_number" : obj.registration_number,
                                "address" : obj.address,
                                "phone" : obj.phone,
                                "logo_url" : obj.logo_url,
                                };
            setFormData(formData => ({
                ...formData,
                ...updatedValue
            }));

            setLoading(false);
        });
    }

    useEffect(() => {
        fetchCompanyDetails();
    }, [])

    const onDrop = (files) => {
        const file = files[0];
        setImage(Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
    };

    const getDropzoneStyle = () => {
        return error.errors && error.errors.image ? {
            borderColor: '#fa5c7c'
        } : {};
    }

    const uploadFile = (file) => {
        if (!(file instanceof File)) {
            // get file name from url
            const full = file.preview.split('/').pop();
            return Promise.resolve({data: {data: {links: {full}}}});
        }
        const data = new FormData();
        data.append('file', file, file.fileName);
        return axios.post('images', data, {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': 'multipart/form-data; boundary=' + data._boundary,
        });
    }

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: t('Settings'), path: '', active: false},
                    {label: t('Company settings'), path: 'settings/company-profile', active: true},
                ]}
                title={'Company settings'}
            />

        <Card className="bg-primary">
            <Card.Body className="profile-user-box">
                <Row>
                    <Col sm={8}>
                        <Row className="align-items-center">
                            <Col className="col-auto">
                                <div className="avatar-lg">
                                    <img
                                        src={profileImg}
                                        style={{ height: '100px' }}
                                        alt=""
                                        className="rounded-circle img-thumbnail"
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <h4 className="mt-1 mb-1 text-white">Oslo flytteservice AS</h4>
                                    <p className="font-13 text-white-50"> Authorised Brand Seller</p>

                                    <ul className="mb-0 list-inline text-light">
                                        <li className="list-inline-item me-3">
                                            <h5 className="mb-1 text-white">$ 25,184</h5>
                                            <p className="mb-0 font-13 text-white-50">Total Revenue</p>
                                        </li>
                                        <li className="list-inline-item">
                                            <h5 className="mb-1 text-white">5482</h5>
                                            <p className="mb-0 font-13 text-white-50">Number of Orders</p>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        
        <Row>
          <Col xs={12} sm={12} md={12} lg={7}>
            <Card>
                  <Card.Body>
                    <Card.Title><strong>{t('Company details')}</strong></Card.Title>
                      <Form noValidate validated={validated} onSubmit={handleSubmit}>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="name">
                                  <Form.Label>{t('Company name')}</Form.Label>
                                  <Form.Control
                                      className="mt-2"
                                      type="text"
                                      placeholder={t('Enter company name')}
                                      name="name"
                                      required
                                      defaultValue={formData.name}
                                      onChange={handleInputChange}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company name.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="registration_number">
                                  <Form.Label>{t('Registration number')}</Form.Label>
                                  <Form.Control
                                      required
                                      className="mt-2"
                                      type="text"
                                      placeholder="Enter registration number"
                                      name="registration_number"
                                      defaultValue={formData.registration_number}
                                      onChange={handleInputChange}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company registration number.')}
                                  </Form.Control.Feedback> 
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="address">
                                  <Form.Label>{t('Address')}</Form.Label>
                                  <Form.Control
                                      required
                                      className="mt-2"
                                      type="text"
                                      placeholder="Enter address"
                                      name="address"
                                      defaultValue={formData.address}
                                      onChange={handleInputChange}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company address.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="phone">
                                  <Form.Label>{t('Phone number')}</Form.Label>
                                  <Form.Control
                                      required
                                      className="mt-2"
                                      type="text"
                                      placeholder="Enter phone number"
                                      name="phone"
                                      defaultValue={formData.phone}
                                      onChange={handleInputChange}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company phone number.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className='my-3'>
                                <Form.Group className="form-group required my-2" as={Col} controlId="upload">
                                  <Form.Label>{t('Logo')}</Form.Label>
                                    <Dropzone required className='w-100' autocomplete="on" onDrop={onDrop} accept={'image/*'} multiple={false}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <>
                                                <div className="dropzone border-1" style={getDropzoneStyle()}>
                                                    <div className="dz-message" {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {
                                                            image ?
                                                                <img src={image.preview}
                                                                        alt={"employee"}
                                                                        className="img-thumbnail"
                                                                        width={200}/>
                                                                : 
                                                                <>
                                                                <img alt='company' src={`https://cleaning.between.as/storage/${formData.logo_url}`} width={200} />
                                                                <h4><strong>{t('Click to take photo')}</strong></h4>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Dropzone> 

                                </Form.Group>
                            <Row>
                                <Col>
                                    <span className="text-danger">
                                        {error.message}
                                    </span>
                                </Col>
                            </Row>
                          </Row>
                          <Row className="mt-5">
                              <Col className="text-end me-2">
                                  <Button disabled={loading && true} variant="primary" className="btn mb-2 " type={"submit"} >
                                      <i className="mdi mdi-content-save me-2"></i>Save
                                  </Button>
                              </Col>
                          </Row>
                      </Form>
                  </Card.Body>
            </Card>
          </Col>
          {/* <Col xs={12} sm={12} md={5}> */}
            {/* <Card>
                  <Card.Body>
                    <Card.Title><strong>{t('Vipps payment settings')}</strong></Card.Title>

                    <Form noValidate>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="first_name">
                                  <Form.Label>{t('Merchant serial number')}</Form.Label>
                                  <Form.Control
                                      className="mt-2"
                                      type="text"
                                      placeholder={t('Enter company name')}
                                      name="first_name"
                                      required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company name.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="email">
                                  <Form.Label>{t('Client ID')}</Form.Label>
                                  <Form.Control
                                      required
                                      className="mt-2"
                                      type="text"
                                      placeholder="Enter registration number"
                                      name="email"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company registration number.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="email">
                                  <Form.Label>{t('Client Secret')}</Form.Label>
                                  <Form.Control
                                      required
                                      className="mt-2"
                                      type="text"
                                      placeholder="Enter address"
                                      name="email"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company address.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="email">
                                  <Form.Label>{t('Primary Vipps Subscription Key')}</Form.Label>
                                  <Form.Control
                                      required
                                      className="mt-2"
                                      type="text"
                                      placeholder="Enter address"
                                      name="email"
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company phone number.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="mt-5">
                              <Col className="text-end me-2">
                                  <Button variant="primary" className="btn mb-2 " type={"submit"} >
                                      <i className="mdi mdi-content-save me-2"></i>Save
                                  </Button>
                              </Col>
                          </Row>
                      </Form>

                  </Card.Body>
            </Card> */}
          {/* </Col> */}
        </Row>
        </>
    );
};

export default PaymentSettings;
