import React from "react";
import {t} from "i18next";
import currencyFormat from "../../helpers/currencyFormat";

const OrderEstimate = ({ id, date, time, total, title, description, orderStatus }) => {

    return (
        <div style={{color: "white"}}
             className="text-center p-2 mt-2">
            <p className="font-12 mb-1 mt-0 py-0">{t('Order ID')}: #{id}</p>
            <h1 className="mb-0 heading">{title}</h1>
            {/* <p className="font-14 mb-1 mt-0 py-0">For {date} at {time}</p> */}
            {description !== '' &&
            <p className="font-14 mb-1 mt-0 py-0">{description}</p>
            }
            {orderStatus !== 'Ongoing' && orderStatus !== 'Approaching' && orderStatus !== 'Completed' &&
                <>
                <br/>
                <h2 className="mb-0" style={{fontSize: "23px", fontWeight: 'bold'}}>{currencyFormat(total)}</h2>
                </>
            }            
            {/* <p className="font-14 mb-1 mt-0 py-0">{description}</p> */}
        </div>
    );
}

export default OrderEstimate;