const order = {
    "id": 4,
    "type": 'cleaning',
    "status": "New",
    "products": [{"id": 1, "product_id": 1, "quantity": 2}, {"id": 2, "product_id": 6, "quantity": 4}, {
        "id": 3,
        "product_id": 8,
        "quantity": 3,
        'item_total': 30,
        'price': 10
    }],
    "total": "15000",
    "moving_date": "2022-11-11",
    "duration": 5,
    "customer": {"id": 1, "name": "Evert Hickle", "email": "stehr.shakira@bergstrom.biz", "phone": "+19135759118"},
    "addresses": {
        "to": {
            "id": 1,
            "street": "Theodor dahls veg 1",
            "place": "Kvernaland",
            "zipcode": "4355",
            "section_id": "Z000",
            "bathroom_quantity": 0,
            "bedroom_quantity": 0,
            "has_elevator": 0,
            "has_garage": 0,
            "house_type": "enebolig",
            "room_quantity": 0,
            "updated_at": "2022-11-11T18:27:43.000000Z",
            "created_at": "2022-11-11T18:27:43.000000Z"
        },
        "from": {
            "id": 2,
            "street": "Malm\u00f8gata 9",
            "place": "Oslo",
            "zipcode": "0566",
            "section_id": "Z000",
            "bathroom_quantity": 1,
            "bedroom_quantity": 3,
            "has_elevator": 1,
            "has_garage": 0,
            "house_size": 91,
            "house_type": "leilighet",
            "room_quantity": 4,
            "updated_at": "2022-11-11T18:27:43.000000Z",
            "created_at": "2022-11-11T18:27:43.000000Z"
        }
    },
}

const products = [{
    "id": 1,
    "title": "Outdoor window cleaning",
    "image_url": "https:\/\/via.placeholder.com\/640x480.png\/00bb33?text=quam",
    "created_at": "2022-11-11T18:27:44.000000Z",
    "price": "3400",
    "is_active": 1,
    "description": "The windows can be max. 3 meters or less above the ground and open inward\n",
    "category": {"id": 4, "title": "qui eos", "created_at": "2022-11-11T18:27:44.000000Z"}
}]

const company = {
    name: 'Wefix Norge AS',
    address: 'Vækerøveien 26',
    zipcode: '0282',
    place: 'Oslo',
    email: 'post@wefixnorge.no',
    phone: '+47 913 45 663',
    orgnr: '923 791 612',
    logo: 'wf_logo.png'
}


export {order, products, company};
