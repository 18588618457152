import React, {useEffect, useState} from 'react';
import OrderSummary from "../quote/OrderSummary";
import {useNavigate, useParams} from "react-router-dom";
import {t} from "i18next";
import {Button, Form, Row} from "react-bootstrap";
import ParkingOptions from "../quote/ParkingOptions";
import EntryOptions from "../quote/EntryOptions";
import CleaningChecklist from "../quote/CleaningChecklist";
import Cookies from 'js-cookie';
import axios from 'axios';

const CustomerPortalConfirm = (props) => {

    const [validated, setValidated] = useState(false);
    const [parking, setParking] = useState('');
    const [entry, setEntry] = useState('');
    const [keyDescription, setKeyDescription] = useState(null);

    const isButtonActive = props.timeBeforeSchedule <= 48  && props.orderStatus !== 'Confirmed';

    const titles = {
        cleaning: t('Your Cleaning Order'),
        moving: t('Your Move Order')
    }

    const {orderId} = useParams();

    const handleParkingOptionSelect = (data) => {
        console.log(data);
        setParking(data);
    };

    const handleEntryOptionSelect = (data) => {
        console.log(data);
        setEntry(data);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);

        if(form.checkValidity()){
            const dataObj = { 
                    "details": {
                        "parking_type": parking, // free or paid
                        "getting_in": entry, // key or meet
                        "key_pickup" : keyDescription
                    }
                }
        
            if(dataObj.getting_in === 'meet'){
                delete dataObj.key_pickup;
            }
            
            console.log(dataObj);

            axios.post('customer-portal/orders/'+orderId+'/confirm', dataObj).then(function(response){

                if(response.data.status.code === '200'){
                    // navigate(`/customer-portal/cleaning-orders/${orderId}/status`, { replace: true });

                    props.setCurrentStep('status');
                    Cookies.set('portal-step', 'status');

                    props.setOrderStatus('Confirmed');
                }
            });
        }
    
      };

    const selectedProducts = Cookies.get('selectedServices') ? JSON.parse(Cookies.get('selectedServices')): [];
    const parkingValue     = props.order.details ? props.order.details.parking_type : "";
    const entryOption      = props.order.details ? props.order.details.getting_in : "";
    const entryOptionDesc  = props.order.details ? props.order.details.key_pickup : "";

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {props.type === 'cleaning' ?
                (
                    <>
                        <ParkingOptions
                            isButtonActive={isButtonActive} 
                            onSelect={handleParkingOptionSelect}
                            order={props.order}
                            value={parkingValue}
                        />
                        <EntryOptions 
                            isButtonActive={isButtonActive}
                            onSelect={handleEntryOptionSelect} 
                            entry={entry}
                            setEntry={setEntry}
                            setKeyDescription={setKeyDescription}
                            order={props.order}
                            entryOption={entryOption }
                            entryOptionDesc={entryOptionDesc}
                        />
                        <CleaningChecklist/>
                    </>
                ) : ''
            }
            <OrderSummary
                hideButton={true}
                total={Cookies.get('total')}
                selectedProducts={selectedProducts}
            />
            {isButtonActive &&
            <Row className={"text-center my-3 mb-5 align-middle"}>
                <div style={{width: "100%"}}>
                    {/* <Button type='submit' size="md" className="float-center mx-1" onClick={() => { window.location.href = `/customer-portal/cleaning-orders/${orderId}/status`}}>
                        <strong className="data-strong">{t('Confirm information')}</strong>
                    </Button> */}
                    <Button type='submit' size="md" className="float-center mx-1">
                        <strong className="data-strong">{t('Confirm information')}</strong>
                    </Button>
                </div>
            </Row>
             }  
            </Form>
        </>
    )
}

export default CustomerPortalConfirm;