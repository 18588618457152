import PhoneInput, {isPossiblePhoneNumber} from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import {t} from "i18next";

interface PhoneWithCountrySelectorParams {
    value: string,
    onChange: *,
    onInvalid: *,
    onValid: *,
    name: string,
    className: string,
}

const PhoneWithCountry = (
    {
        value,
        placeholder,
        isInvalid,
        onChange,
        onInvalid,
        onValid,
        name,
        className = ''
    }: PhoneWithCountrySelectorParams) => {

    const style = {};
    console.log(value);
    if (isInvalid) {
        style.borderColor = 'red';
    }

    return (
        <PhoneInput
            style={style}
            defaultCountry="NO"
            country="NO"
            countryCallingCodeEditable={false}
            international={true}
            className={className}
            placeholder={placeholder || t("Enter your phone number")}
            value={value || ''}
            rules={{required: true, validate: isPossiblePhoneNumber}}
            name={name}
            onChange={onChange}
            onBlur={(e) => {
                const value = e.target.value;
                if (value.length > 3 && !isPossiblePhoneNumber(value)) {
                    console.log('invalid value ', value);
                    onInvalid(value);
                } else {
                    console.log('valid value', value);
                    onValid();
                }
            }}
        />
    );
}

export default PhoneWithCountry;