import {Button, Card, Col, Container, Form, FormGroup, Modal, ModalBody, Row, Alert} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {t} from "i18next";
import currencyFormat from "../../helpers/currencyFormat";
import axios from "axios";
import Cookies from "js-cookie";
import OtpInput from "react18-input-otp";

const BACKSPACE_KEY = 8;
const ENTER_KEY = 13;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;


const SMSConfirmation = ({currentPin, setCurrentPin, onConfirm, onCancel, order, setCurrentStep, toggleSms, orderStatus, setOrderStatus}) => {

    console.log(currentPin);

    const [pinError, setPinError] = useState(false);
    const buttonRef = useRef();
    const [confirmed, setConfirmed] = useState(false);

    const [otp, setOtp] = useState('');

    const handleOtpChange = ( value ) => {
        setOtp(value);
    }

    const handleConfirm = () => {
        console.log(currentPin.toString(), otp);
        if(currentPin.toString() !== otp){
            setPinError(true);
        }else{
            
            axios.post(`customer-portal/orders/${order.id}/accept-quote`, {pin : currentPin})
            .then(response => {

                console.log('accept quote', response);
                if(response.data.status.code === '200' && response.data.status.message === 'Invalid pin code'){
                    setPinError(true);
                }else{
                    setPinError(false);
                    setConfirmed(true); 
                }
            })


        }
    }

    const title = confirmed ? t('Order confirmed') : t('Enter pin from SMS');

    const handleConfirmClick = () => {
        setCurrentStep('confirmation');
        setOrderStatus("New");
        Cookies.set('portal-step', 'confirmation');
        toggleSms();
    }

    const inputStyle = {
        boxShadow : '0 2px 4px 2px #dddddd',
        width : '50px',
        height : '50px',
        fontSize : '20px',
        borderWidth : '0px!important',
        lineHeight : '2em',
        border: 'none', 
        WebkitAppearance : 'none',
        WebkitBoxShadow: '3px 3px 6px 6px #addacolor',
    }
    return (
        <>
            <Container className="my-3">
                <h3 className="bold text-center text-black"
                    style={{fontSize: '21px'}}>{title}</h3>
                {confirmed
                    ? <>
                        <Row className="text-center my-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="46.85" height="46.85"
                                 viewBox="0 0 46.85 46.85">
                                <path id="badge-check"
                                      d="M21.531,30.315a1.453,1.453,0,0,1-2.068,0l-5.124-5.124a1.462,1.462,0,1,1,2.068-2.068l4.09,4.09L29.711,18a1.462,1.462,0,0,1,2.068,2.068Zm9.864-26.14a9.707,9.707,0,0,1,11.282,11.28,9.543,9.543,0,0,1,4.173,7.888,9.732,9.732,0,0,1-4.173,8.052A9.712,9.712,0,0,1,31.395,42.677a9.693,9.693,0,0,1-7.97,4.173,9.581,9.581,0,0,1-7.97-4.173A9.707,9.707,0,0,1,4.175,31.395,9.722,9.722,0,0,1,0,23.342a9.534,9.534,0,0,1,4.175-7.888,9.7,9.7,0,0,1,11.28-11.28A9.534,9.534,0,0,1,23.342,0a9.722,9.722,0,0,1,8.052,4.175ZM16.873,7.6l-1.162-.355a6.773,6.773,0,0,0-8.464,8.464L7.6,16.873l-1.069.567a6.734,6.734,0,0,0-3.605,5.9,6.9,6.9,0,0,0,3.605,6.067l1.069.567-.355,1.162A6.772,6.772,0,0,0,15.711,39.6l1.162-.357.567,1.071a6.76,6.76,0,0,0,5.984,3.605,6.858,6.858,0,0,0,5.984-3.605l.567-1.071,1.162.357A6.772,6.772,0,0,0,39.6,31.139l-.357-1.162,1.071-.567a6.89,6.89,0,0,0,3.605-6.067,6.729,6.729,0,0,0-3.605-5.9l-1.071-.567.357-1.162a6.772,6.772,0,0,0-8.464-8.464L29.976,7.6l-.567-1.069a6.941,6.941,0,0,0-5.984-3.688,6.84,6.84,0,0,0-5.984,3.688Z"
                                      fill="#536de6"/>
                            </svg>
                        </Row>
                        <Row className="text-center my-2 font-14">
                            <p>{t("You can chaAnge your order at any time up to 2 days before the moving date. Remember to always have the inventory up to date. We have also send you a order confirmation on e-mail.")}</p>
                        </Row>
                        <Row className="text-center my-2">
                            <Col></Col>
                            <Col xs={10}>
                                <Button
                                    className="font-15"
                                    style={{width: "100%"}}
                                    onClick={ handleConfirmClick }
                                >{t('Go to confirmation page')}</Button>
                            </Col>
                            <Col></Col>
                        </Row>
                    </>
                    : <>
                        <FormGroup as={Row} className="my-3">
                            <OtpInput 
                                value={ otp } 
                                onChange={ handleOtpChange } 
                                inputStyle={ inputStyle } 
                                numInputs={4} 
                                separator={<span className="mx-1"></span>}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                containerStyle={{display:'flex', justifyContent:'center', alignItems: 'center'}}
                            />
                        </FormGroup>
                        <Row className="text-center my-1 mb-3">
                            {pinError &&
                            <Alert className="mb-2" variant={'danger'}>{t('Pincode mismatched')}</Alert>
                            }
                            <p className="my-0">{t('Please enter pin to confirm the order.')}</p>
                            <p className="my-0">{t('Didn\'t receive an SMS?')}</p>
                            <p className="my-0"><a href="#">{t('Send SMS again')}</a></p>
                        </Row>
                        <Row className="my-1">
                            <Col className="text-end pe-1">

                                <Button
                                    ref={buttonRef}
                                    className="font-15"
                                    style={{width: "100%"}}
                                    onClick={handleConfirm}
                                    disabled={currentPin === 0 ? true : false}
                                >{t('Confirm')}</Button>
                            </Col>
                            <Col className="text-start ps-1">
                                <Button
                                    onClick={onCancel}
                                    variant="outline-primary"
                                    className="font-15"
                                    style={{width: "100%"}}
                                >{t('Cancel')}</Button>
                            </Col>
                        </Row>
                    </>

                }
            </Container>
        </>
    )
}


const OrderSummary = ({total, order, hideButton, selectedProducts, currentPin, setCurrentPin, setCurrentStep, currentStep, orderStatus, setOrderStatus}) => {

    // console.log('selected products', selectedProducts);

    const [showSms, setShowSms] = useState(false);
    const onConfirm = () => {

        //send OTP
        if(currentPin === 0){
            sendPin(order.id);
        }

        toggleSms();
        // TODO API request
    }

    const sendPin = (orderId) => {
        axios.post(`customer-portal/orders/${orderId}/send-pin`)
        .then(response => {
            console.log('send OTP', response);
            setCurrentPin(response.data.data.pin);
        })
    }

    const toggleSms = () => {
        setShowSms(!showSms);
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title className={"mb-0 font-15"}>
                        <strong>{t("Order Summary")}</strong>
                    </Card.Title>
                    <Row className="pt-1">
                        <Col xs={8}><strong className="data-strong">{t("Description")}</strong></Col>
                        <Col className={"text-end"}><strong className="data-strong">{t("Cost")}</strong></Col>
                    </Row>
                    <hr className={"my-1"}/>
                    {selectedProducts.map((selectedProduct, i) => {
                        const product = selectedProduct;
                        // if(product.product_id !== 1){
                            return <Row key={i}>
                                <Col xs={8}><strong className="data-strong">{t(product?.title)}</strong></Col>
                                <Col className={"text-end"}><strong className="data-strong">{currencyFormat(product?.item_total)}</strong></Col>
                            </Row>
                        // }
                    })}
                    <hr className={"my-1"}/>
                    <Row>
                        <Col><strong className="data-strong">{t("Total")}</strong></Col>
                        <Col className={"text-end"}>
                            <strong className="data-strong">{currencyFormat(total)}</strong></Col>
                    </Row>
                    {
                        currentStep === 'quote' &&
                            <Row className={"text-center mt-3 align-middle"}>
                                <div style={{width: "100%"}}>
                                    <Button size="md" className="float-center mx-1" style={{width: "160px"}}
                                            onClick={onConfirm}>
                                        <strong className="data-strong">{t("Confirm quote")}</strong>
                                    </Button>
                                </div>
                            </Row>
                    }
                </Card.Body>
            </Card>
            <Modal show={showSms} centered dialogClassName="modal-mobile" keyboard={true} onEscapeKeyDown={() => {
                setShowSms(false)
            }}>
                <ModalBody>
                    <SMSConfirmation 
                    currentPin={currentPin} 
                    setCurrentPin={setCurrentPin} 
                    onConfirm={onConfirm} 
                    onCancel={toggleSms} 
                    order={order}
                    setCurrentStep={setCurrentStep}
                    toggleSms={toggleSms}
                    orderStatus={orderStatus}
                    setOrderStatus={setOrderStatus}
                    />
                </ModalBody>
            </Modal>
        </>

    );
}

export default OrderSummary;