import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import parse from 'html-react-parser';
import {t} from "i18next";
import Cookies from "js-cookie";
import { addressFormat } from "../../helpers/common";

const getRows = (type, order) => {
    // const order = JSON.parse(Cookies.get('order'))
    order = order  ? order : JSON.parse(Cookies.get('order'));
    const fromAddress = order.addresses.from;
    // const address = `${fromAddress.street}<br/>${fromAddress.zipcode} ${fromAddress.place}`
    const address = addressFormat(fromAddress);
    const cleaningType = t('Relocation cleaning');
    return [
        [
            {header: t('Consultant'), body: 'Torstein Næss'},
            {header: t('Your info'), body: order.customer.name},
        ], [
            {header: t('Address'), body: address},
            {header: t('Cleaning type'), body: cleaningType},
        ]
    ];
}

const OrderOverview = ({type, order}) => {
    const rows = getRows(type, order);

    return (
        <Card className="mt-3">
            <Card.Body>
                {rows.map((row, i) => {
                    return (
                        <>
                            <Row key={i}>
                                {row.map((col, i) => {
                                    return (
                                        <Col key={i}>
                                            <p className="mb-0">{t(col.header)}</p>
                                            <strong className="data-strong">{parse(col.body)}</strong>
                                        </Col>
                                    )
                                })}
                            </Row>
                            { i < rows.length - 1  ? <hr/> : ''}
                        </>
                    );
                })}
            </Card.Body>
        </Card>
    );
}

export default OrderOverview;