// @flow
import React, { useEffect, useState } from 'react';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';

// components
import PageTitle from '../../../../components/PageTitle';

import { t } from 'i18next';
import profileImg from '../../../../assets/images/users/avatar-2.jpg';
import PhoneWithCountry from '../../../../components/PhoneWithCountry';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import MessageAlert from '../../../../components/MessageAlert';

// main component
const PaymentSettings = (): React$Element<React$FragmentType> => {

  const [image, setImage] = useState(null);
  const [validated, setValidated] = useState(false);
  const companyId = 1;
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    serial_number : '',
    client_id : '',
    client_secret : '',
    subscription_key : '',
  });

  const handleSubmit = (event) => {
        
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    if(form.checkValidity()){
            submitForm(formData);
    }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const submitForm = ( data ) => {
        //send the report of the job
        axios.post('/payment-settings/', data).then(function(response){
            console.log('payment settings response', response.data);

            setValidated(false);
        });
    }

    const fetchPaymentSettings = () => {
        axios.get('/payment-settings/').then(function(response){
            console.log('company response', response.data);
            const obj = response.data.data;
            let updatedValue = {"serial_number" : obj.serial_number,
                                "client_id" : obj.client_id,
                                "client_secret" : obj.client_secret,
                                "subscription_key" : obj.subscription_key
                                };
            setFormData(formData => ({
                ...formData,
                ...updatedValue
            }));

            setLoading(false);
        });
    }

    useEffect(() => {
        fetchPaymentSettings();
    }, [])

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: t('Settings'), path: '', active: false},
                    {label: t('Payment settings'), path: 'settings/company-profile', active: true},
                ]}
                title={'Payment settings'}
            />
        
        <Row>
          <Col xs={12} sm={12} md={6}>
            <Card>
                  <Card.Body>
                    <Card.Title><strong>{t('Vipps payment settings')}</strong></Card.Title>

                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="serial_number">
                                  <Form.Label>{t('Merchant serial number')}</Form.Label>
                                  <Form.Control
                                      className="mt-2"
                                      type="text"
                                      placeholder={t('Enter serial number')}
                                      name="serial_number"
                                      required
                                      onChange={handleInputChange}
                                      defaultValue={formData.serial_number}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid serial number.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="client_id">
                                  <Form.Label>{t('Client ID')}</Form.Label>
                                  <Form.Control
                                      required
                                      className="mt-2"
                                      type="text"
                                      placeholder="Enter client id"
                                      name="client_id"
                                      onChange={handleInputChange}
                                      defaultValue={formData.client_id}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid serial number.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="client_secret">
                                  <Form.Label>{t('Client Secret')}</Form.Label>
                                  <Form.Control
                                      required
                                      className="mt-2"
                                      type="text"
                                      placeholder="Enter client secret"
                                      name="client_secret"
                                      onChange={handleInputChange}
                                      defaultValue={formData.client_secret}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a client secret.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="subscription_key">
                                  <Form.Label>{t('Primary Vipps Subscription Key')}</Form.Label>
                                  <Form.Control
                                      required
                                      className="mt-2"
                                      type="text"
                                      placeholder="Enter subscription key"
                                      name="subscription_key"
                                      onChange={handleInputChange}
                                      defaultValue={formData.subscription_key}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid subscription key')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="mt-5">
                              <Col className="text-end me-2">
                                  <Button disabled={loading && true} variant="primary" className="btn mb-2 " type={"submit"} >
                                      <i className="mdi mdi-content-save me-2"></i>Save
                                  </Button>
                              </Col>
                          </Row>
                      </Form>

                  </Card.Body>
            </Card>
          </Col>
        </Row>
        </>
    );
};

export default PaymentSettings;
