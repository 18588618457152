import React, {useState} from 'react';
import {Button, Card, Col, Row} from "react-bootstrap";
import {t} from "i18next";
import currencyFormat from "../../helpers/currencyFormat";
import axios from 'axios';

const CleaningServices = ({services, order, onServiceChange, selectedProducts}) => {
    const [list, setList] = useState([]);
    const [selected, setIsSelected] = useState(false);

    const toggleService = (service, key, action) => {
        console.log(action);
        setIsSelected(true);

        //DELETE
        if(action === 1){
            axios.delete(`customer-portal/orders/${order.id}/products/${service.id}`)
            .then(response => {
                console.log('delete', response);
                onServiceChange();
                setIsSelected(false);
            })
        }else{
            axios.post(`customer-portal/orders/${order.id}/products`, {product_id : service.id, quantity : 1})
            .then(response => {
                console.log('update', response);
                onServiceChange();
                setIsSelected(false);
            })
        }

    }

    if(order){
    return (
        <>
            <Card style={{maxHeight: '500px', overflow: 'scroll'}}>
                <Card.Body>
                    <Card.Title className={"mb-0 "}><strong>{t('Other Services')}</strong></Card.Title>
                    {services.length > 0 ?
                        services.map((service, i) => {
                        const added = selectedProducts.filter(obj => service.id === obj.product_id ? true : false );
                        return ( <Row className="my-2 p-1" style={{borderRadius: 15, backgroundColor: "#FAFBFE"}}
                                     key={i}>
                                    <Col className="p-2" key={i + '-text-div'}>
                                        <p className="bold my-0" key={i + '-title'}>{t(service?.title)}</p>
                                        <span key={i + '-description'}
                                            className="d-block"><small key={i + '-description-text'}>{t(service?.description) || 'Lorem ipsum dolor'}</small></span>
                                        
                                    </Col>
                                    <Col xs="4" className="my-auto text-center" key={i + '-button-div'}>
                                    <p key={i + '-price'}
                                            className="mb-0 float-end align-content-end text-end bold">{currencyFormat(service?.price)}</p>
                                        <Button
                                            disabled={selected}
                                            variant={added.length > 0 ? 'danger' : 'primary'}
                                            key={i + '-button'}
                                            onClick={e => {
                                                toggleService(service, i, added.length);
                                            }}
                                            className="font-10 align-middle my-auto"
                                            size={"sm"}
                                            style={{width: '80px'}}
                                        >{t(added.length > 0 ? t('Remove') : t('Add'))}</Button>
                                    </Col>
                                </Row>
                        )
                    })
                    :
                        <p className="mt-3 bold">{t('Loading...')}</p>
                    }
                </Card.Body>
            </Card>
        </>
    )}else{
        return <Card><Card.Body><p className="mt-3 bold">{t('Loading...')}</p></Card.Body></Card>
    }
}

export default CleaningServices;