import {Card, Image, Row} from "react-bootstrap";
import route from "../../assets/images/orders/route.png";
import React from "react";


const MovingRoute = () => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    Moving Route
                </Card.Title>
                <Image src={route} width={"100%"}/>
            </Card.Body>
        </Card>
    );
}

export default MovingRoute;