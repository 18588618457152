import {Card, Image, Navbar} from "react-bootstrap";
import logo from "../../assets/images/logos/wf_logo.png";
import React from "react";
import {company} from "./fakedata";
import {t} from "i18next";

const CompanyHeader = (props) => {
    const details = props.companyDetails;

    return (
        <div style={{ borderRadius: 0, height: "74px"}} className="d-block">
            <Navbar expand="lg" variant="light" style={{height: "68px", backgroundColor: "white"}} sticky={true}>
                <Navbar.Brand href="#" style={{width: "100%"}} className="mx-0">
                    <Image src={logo} height={50}/>
                    <div className="d-inline-block text-end ms-auto float-end">
                        <p className="font-12 mb-0">{t('Call us anytime')}</p>
                        <h4><strong>{company.phone}</strong></h4>
                    </div>
                </Navbar.Brand>
            </Navbar>
        </div>
    );
};

export default CompanyHeader;