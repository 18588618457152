import React, {useEffect, useState} from 'react';
import OrderSummary from "../quote/OrderSummary";
import MovingRoute from "../quote/MovingRoute";
import OrderOverview from "../quote/OrderOverview";
import OrderEstimate from "../quote/OrderEstimate";
import {useParams} from "react-router-dom";
import CompanyHeader from "../quote/CompanyHeader";
import Layout from "../quote/Layout";
import {t} from "i18next";
import moment from "moment";
import CustomerPortalQuote from './CustomerPortalQuote';
import CustomerPortalConfirm from './CustomerPortalConfirm';
import CustomerPortalStatus from './CustomerPortalStatus';
import Cookies from 'js-cookie';
import axios from 'axios';
import CustomerPortalAccepted from './CustomerPortalAccepted';
import { capitalizeWords } from '../../helpers/common';

const CustomerPortal = ({type}) => {

    const {orderId} = useParams();
    const [currentStep, setCurrentStep] = useState(Cookies.get('portal-step') ? Cookies.get('portal-step') : 'quote');
    // const [currentStep, setCurrentStep] = useState('quote');
    const [currentEditor, setCurrentEditor] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [total, setTotal] = useState(0);
    const [allProducts, setAllProducts] = useState([]);
    const [newOrder, setOrder] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [timeBeforeSchedule, setTimeBeforeSchedule] = useState(null);
    const [startTime, setStartTime] = useState('');

    useEffect(() => {
        fetchAllProducts();

        if(Cookies.get('current-id') !== orderId){
            Cookies.set('current-id', orderId);
            setCurrentStep('quote');
            Cookies.set('portal-step', 'quote');
        }

    }, []);

    const fetchAllProducts = () => {
        axios.get('customer-portal/products')
        .then(response => {
            const p = response.data.data;
            if(p.length > 0){
                p.map(obj => {
                    if(obj.is_active === 1 && obj.id !== 1){
                        setAllProducts(allProducts => [...allProducts, obj]);
                    }
                })
                fetchSelectedProducts();
            }
        })
    }

    const fetchSelectedProducts = () => {
        axios.get(`customer-portal/orders/${orderId}`)
        .then(response => {
            var data = response.data.data;
            var p = data.products;
            if(response.data.data.products.length > 0){
                setSelectedServices(p);
            }
            // console.log(data.total);
            setTotal(data.total);

            //set order
            setOrder(data);

            //update cookie
            Cookies.set('order', JSON.stringify(data));
            Cookies.set('total', data.total);
            Cookies.set('selectedServices', JSON.stringify(p));

            setOrderStatus(data.status);

            getTimeDiff(data.status, data.schedule.date);

            if(data.schedule.start_time !== null){
                setStartTime('at '+data.schedule.start_time);
            }

            console.log('fetch customer portal', response);
        })
    }

    const titles = {
        Lead: t('Order Estimate'),
        New: t('Order Confirmation'),
        Assigned: t('Order Confirmation'),
        Confirmed: t('Thank you!'),
        Ongoing: t('Cleaning Status'),
        Approaching: t('Your cleaning date is approaching.'),
        Completed: t('Cleaning is Completed'),
    }

    const descriptions = {
        Lead: capitalizeWords('For '+moment().format('dddd, DD. MMMM YYYY')+' '+startTime),
        New: capitalizeWords('For '+moment().format('dddd, DD. MMMM YYYY')+' '+startTime),
        Assigned: capitalizeWords('For '+moment().format('dddd, DD. MMMM YYYY')+' '+startTime),
        Confirmed: capitalizeWords('For '+moment().format('dddd, DD. MMMM YYYY')+' '+startTime),
        Approaching: t('We need to collect some more information to get ready for your order.'),
        Ongoing: '',
        Completed: '',      
    }

    const getTimeDiff = (status, date) => {
        const now  = moment.utc();
        var end = moment(date);
        // var end = moment("2023-01-06");
        var diff = end.diff(now, 'hours');
        setTimeBeforeSchedule(diff);
        
        if(status === "New" || status === 'Assigned'){
            if(diff <= 48){
                setCurrentStep('confirmation');
                Cookies.set('portal-step', 'confirmation');
            }else{
                setCurrentStep('quote');
                Cookies.set('portal-step', 'quote');
            }
        }

        if(status === 'Confirmed'){
            setCurrentStep('confirmation');
            Cookies.set('portal-step', 'confirmation');
        }

        
        if(status === 'Completed' || status === 'Ongoing'){
            setCurrentStep('status');
            Cookies.set('portal-step', 'status');
        }
    }
    
    console.log(timeBeforeSchedule < 48, timeBeforeSchedule);
    return (
        <>
            <Layout>
                <CompanyHeader
                    companyDetails={{}}
                />
                <OrderEstimate 
                    title={titles[(orderStatus === 'New' || 
                    orderStatus ==='Assigned') && timeBeforeSchedule < 48 ? 'Approaching' : orderStatus]} 
                    date={moment().format('dddd, DD. MMMM YYYY')} 
                    description={descriptions[(orderStatus === 'New' || 
                    orderStatus ==='Assigned') && timeBeforeSchedule < 48 ? 'Approaching' : orderStatus]} 
                    id={orderId}
                    total={total}
                    orderStatus={orderStatus}
                />
                {newOrder && <OrderOverview type={type} order={newOrder}/> }

                { currentStep === 'quote' && orderStatus === 'Lead'? 

                <CustomerPortalQuote 
                    type={'cleaning'} 
                    currentStep={currentStep} 
                    setCurrentStep={setCurrentStep} 
                    allProducts={allProducts}
                    setAllProducts={setAllProducts}
                    selectedServices={selectedServices}
                    setSelectedServices={setSelectedServices}
                    fetchSelectedProducts={fetchSelectedProducts}
                    newOrder={newOrder}
                    setOrder={setOrder}
                    orderStatus={orderStatus}
                    setOrderStatus={setOrderStatus}
                    total={total}
                />

                : (currentStep === 'quote' || currentStep === 'confirmation') && 
                ((orderStatus === 'New' || orderStatus === 'Assigned') && 
                timeBeforeSchedule > 48) ?
                <>
                
                <CustomerPortalAccepted
                    newOrder={newOrder} 
                    type={'cleaning'} 
                    currentStep={currentStep} 
                    setCurrentStep={setCurrentStep} 
                    />
                </>

                : (currentStep === 'confirmation' || currentStep === 'status') && 
                (((orderStatus === 'New' || orderStatus === 'Assigned') && 
                timeBeforeSchedule <= 48) || 
                orderStatus === 'Confirmed') ?
                <CustomerPortalConfirm 
                    type={'cleaning'} 
                    currentStep={currentStep} 
                    setCurrentStep={setCurrentStep} 
                    timeBeforeSchedule={timeBeforeSchedule}
                    orderStatus={orderStatus}
                    setOrderStatus={setOrderStatus}
                    order={newOrder}
                />
                
                : currentStep === 'status' && 
                ( ['Completed', 'Ongoing', 'Paid'].includes(orderStatus) ) ?
                
                <CustomerPortalStatus
                    order={newOrder}
                    type={'cleaning'}
                    currentStep={currentStep} 
                    setCurrentStep={setCurrentStep} 
                />
                
                :
                <></>
                }

                {type === 'moving' ? (currentEditor ? '' : <MovingRoute/>) : ''}

            </Layout>
        </>)
        ;
};


export default CustomerPortal;