import {
    Button,
    Card,
    Col,
    Form,
    Row
} from "react-bootstrap";

import React, {useState, useEffect} from "react";
import {t} from "i18next";
import _, {isUndefined} from 'lodash';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
    faBath,
    faBedFront, faBoxes,
    faBuildings,
    faDoorOpen, faDrawSquare,
    faElevator,
    faGarage,
    faHouse,
} from "@fortawesome/pro-light-svg-icons";
import axios from "axios";
import { addressFormat } from "../../helpers/common";

function Popup() {
    return null;
}

const Viewer = ({details, currentData, toggleEditor, title, isEditable}) => {

    if(currentData){
        const address = currentData?.addresses?.from;
        const addressText = addressFormat(address);
        return (
            <>
                <Card.Title className={"mb-0 font-15"}><strong>{title}</strong></Card.Title>
                <span className="font-15">{addressText}</span> <a href={`https://www.google.com/maps/place/${addressText}`} target="_blank">(Get directions)</a>
                <div className="mt-2">
                {details.map(detail => {
                    return (
                        <Row className={"mt-1"}>
                            {detail.map(col => {
                            const value = address[col?.key];
                                return (
                                    <>
                                        <Col><p className={"font-14 my-0"}>
                                            <FontAwesomeIcon icon={col.icon} className="pe-1" style={{width : '15px'}}/>
                                            {/* {t(col.name)}: {_.capitalize(t(getValue(col.key, data)))} */}
                                            {t(col.name)}: {_.capitalize(value)}
                                        </p></Col>
                                    </>
                                );
                            })}
                        </Row>
                    )
                })}
                </div>
                {isEditable &&
                <Row className={"text-center mt-2"}>
                    <Col>
                        <Button variant="outline-primary"
                                className="mx-1 font-10"
                                onClick={toggleEditor}
                                style={{height: "30px"}}>{t('Edit information')}</Button>
                    </Col>
                </Row>
                }
            </>
        );
    }else{
        return <p className="bold">{t('Loading...')}</p>
    }
}

const Editor = ({fields, currentData, onConfirm, onCancel, validated}) => {

    return (
        <>
            <h4 className="bold">{t('Edit address information')}</h4>
            <Form onSubmit={onConfirm} noValidate validated={validated} >
                {fields.flat().map((field, i) => {
                    // const value = getValue(field.key, data);
                    const value = currentData.addresses.from[field.key];
                    let input = <Form.Control 
                    required type="number" 
                    name={field.key} 
                    id={field.key} 
                    style={{ borderRadius: '20px !important' }} 
                    defaultValue={value ? value : 0} 
                    min={0} 
                    onChange={(e) => {}}/>;
                    if (field.type === 'select') {
                        input = (
                            <Form.Select
                            name={field.key} 
                            defaultValue={value ? value : 0}
                            id={field.key} >
                                {field.options.map(option => {
                                    return <option value={option}>{_.capitalize(t(option))}</option>
                                })}
                            </Form.Select>
                        );
                    }
                    return (
                        <Row className="py-1" key={i}>
                            <Col>
                                <FontAwesomeIcon icon={field.icon} className="pe-1"/>
                                {t(field.name)}
                            </Col>
                            <Col>
                                {input}
                            </Col>
                        </Row>
                    )
                })}
                <Row className="py-1">
                    <Col className="text-end">
                        <Button
                            className="font-15"
                            style={{width: "100%"}}
                            // onClick={onConfirm}
                            type={'submit'}
                        >{t('Confirm')}</Button>
                    </Col>
                    <Col className="text-start">
                        <Button
                            variant="outline-primary"
                            className="font-15"
                            style={{width: "100%"}}
                            onClick={onCancel}
                        >{t('Cancel')}</Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const getValue = (key, data) => {
    let value = data[key];
    if (key.match(/garage|elevator|storage/)) {
        value = !!value ? 'Yes' : 'No'
    }
    return !isUndefined(value) ? value : 0;
};

const AddressInformation = ({type, data, newData, isEditable = true}) => {

    const [currentData, setCurrentData] = useState(newData);

    useEffect(() => {
        setCurrentData(newData);
    }, [newData]);

    const [validated, setValidated] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const title = type === 'cleaning' ? t('Address Information') : t('Moving From');

    let fields = [
        [
            {
                name: 'Type', key: 'house_type', type: 'select', options: [
                    'leilighet', 'enebolig', 'tomannsbolig', 'rekkehus'
                ], icon: faHouse
            },
            {name: 'Bedrooms', key: 'bedroom_quantity', type: 'input', icon: faBedFront},
        ],
        [
            {name: 'Size', key: 'house_size', type: 'input', icon: faDrawSquare},
            {name: 'Bathrooms', key: 'bathroom_quantity', type: 'input', icon: faBath}
        ],
        [
            {name: 'Floors in building', key: 'floor', type: 'input', icon: faBuildings},
            {name: 'Total rooms', key: 'room_quantity', type: 'input', icon: faDoorOpen},
        ]
    ];

    if (type === 'moving') {
        fields = [...fields, ...[
            [
                {name: 'Elevator', key: 'has_elevator', type: 'select', options: ['Yes', 'No'], icon: faElevator},
                {name: 'Garage', key: 'has_garage', type: 'select', options: ['Yes', 'No'], icon: faGarage}
            ],
            [{name: 'Storage rooms', key: 'has_storage', type: 'input', icon: faBoxes}],
        ]]
    }

    const toggleEditor = () => {
        setEditMode(!editMode);
    };

    const handleAddressChange = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }else{
            toggleEditor();
            const formData = event.target;
            const dataObj = {type: 'from', 
                street : newData.street,
                zipcode : newData.zipcode,
                place : newData.place,
                bathroom_quantity : formData.bathroom_quantity?.value,
                bedroom_quantity : formData.bedroom_quantity?.value,
                has_elevator : formData.has_elevator?.value,
                has_garage : formData.has_garage?.value,
                has_storage : formData.has_storage?.value,
                house_size : formData.house_size?.value,
                house_type : formData.house_type?.value,
                room_quantity : formData.room_quantity?.value};

                console.log(dataObj);
    
                axios.put(`customer-portal/orders/${currentData.id}/addresses`, dataObj)
                .then(response => {
                    console.log('address update', response);

                    setCurrentData(currentData => ({
                        ...currentData, 
                        addresses : {from : response.data.data}
                    }))
                })
        }
        setValidated(true);
    
    };

    return (
        <>
            <Card>
                <Card.Body>
                    {!editMode
                        ? 
                            <Viewer
                            data={data}
                            currentData={currentData}
                            details={fields}
                            toggleEditor={toggleEditor}
                            title={title}
                            isEditable={isEditable}/>
                        : <Editor
                            fields={fields}
                            currentData={currentData}
                            onConfirm={handleAddressChange}
                            onCancel={toggleEditor}
                            validated={validated}
                        />
                    }
                </Card.Body>
            </Card>
        </>
    );
};

export default AddressInformation;
